/* eslint-disable global-require */

// The top-level (parent) route
import { Route, Context } from 'universal-router';

interface ExtendedRoute<C extends Context = any, R = any> extends Route {
  protected?: boolean;
  redirectedToMainHost?: boolean;
  children?: ExtendedRoutes<C, R> | null;
}

export type ExtendedRoutes<C extends Context = Context, R = any> = Array<
  ExtendedRoute<C, R>
>;

const routes: ExtendedRoute = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    // Public routes
    {
      path: '',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: '/c/:slug',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'catalog' */ './catalog'),
    },
    {
      path: '/p/:id',
      load: () => import(/* webpackChunkName: 'product' */ './product'),
    },
    {
      path: '/s/:slug',
      load: () => import(/* webpackChunkName: 'selection' */ './selection'),
    },
    {
      path: '/cart',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'cart' */ './cart'),
    },
    {
      path: '/checkout/:orderId',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'checkout' */ './checkout'),
    },
    {
      path: '/checkout/:orderId/success',
      redirectedToMainHost: true,
      load: () =>
        import(/* webpackChunkName: 'checkout-success' */ './checkout-success'),
    },
    {
      path: '/signin',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'signin' */ './signin'),
    },
    {
      path: '/signout',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'signout' */ './signout'),
    },
    // {
    //   path: '/signup',
    //   load: () => import(/* webpackChunkName: 'signup' */ './signup'),
    // },
    {
      path: '/signup-for-business',
      redirectedToMainHost: true,
      load: () =>
        import(
          /* webpackChunkName: 'signup-for-business' */ './signup-for-business'
        ),
    },
    {
      path: '/email-recovery',
      redirectedToMainHost: true,
      load: () =>
        import(/* webpackChunkName: 'email-recovery' */ './email-recovery'),
    },
    {
      path: '/email-confirm/:token',
      redirectedToMainHost: true,
      load: () =>
        import(/* webpackChunkName: 'email-confirm' */ './email-confirm'),
    },

    // content pages
    {
      path: '/brands',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'brands' */ './brands'),
    },
    {
      path: '/about',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'about' */ './about'),
    },
    // {
    //   path: '/terms-of-use',
    //   redirectedToMainHost: true,
    //   load: () => import(/* webpackChunkName: 'terms' */ './terms'),
    // },
    // {
    //   path: '/privacy-policy',
    //   redirectedToMainHost: true,
    //   load: () => import(/* webpackChunkName: 'privacy' */ './privacy'),
    // },
    {
      path: '/placement',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'placement' */ './placement'),
    },
    {
      path: '/categories',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'categories' */ './categories'),
    },
    {
      path: '/how-to-buy',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'how-to-buy' */ './how-to-buy'),
    },

    {
      path: '/photo-requirements',
      redirectedToMainHost: true,
      load: () =>
        import(
          /* webpackChunkName: 'photo-requirements' */ './photo-requirements'
        ),
    },
    {
      path: '/payment',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'payment' */ './payment'),
    },

    // magazine
    {
      path: '/magazine',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'magazine' */ './magazine'),
    },
    {
      path: '/magazine/article/kosmicheskaya-paskha',
      redirectedToMainHost: true,
      load: () =>
        import(
          /* webpackChunkName: 'article-space-easter' */ './article-space-easter'
        ),
    },
    {
      path: '/magazine/article/proekt-etika',
      redirectedToMainHost: true,
      load: () =>
        import(/* webpackChunkName: 'article-etika' */ './article-etika'),
    },
    {
      path: '/magazine/article/gaspacho',
      redirectedToMainHost: true,
      load: () =>
        import(/* webpackChunkName: 'article-gaspacho' */ './article-gaspacho'),
    },
    {
      path: '/magazine/article/photographers',
      redirectedToMainHost: true,
      load: () =>
        import(
          /* webpackChunkName: 'article-photographers' */ './article-photographers'
        ),
    },
    {
      path: '/magazine/article/yoga',
      redirectedToMainHost: true,
      load: () =>
        import(
          /* webpackChunkName: 'article-yoga' */ './article-yoga'
        ),
    },

    // NOTE: redirect loop with not-found route fixed with /b/:slug
    {
      path: '/b/:slug',
      load: () => import(/* webpackChunkName: 'brand' */ './brand'),
    },

    // Restricted access routes

    {
      path: '/brand/settings',
      redirectedToMainHost: true,
      load: () =>
        import(/* webpackChunkName: 'brand-settings' */ './brand-settings'),
    },
    {
      path: '/brand/products',
      redirectedToMainHost: true,
      load: () =>
        import(/* webpackChunkName: 'brand-products' */ './brand-products'),
    },
    {
      path: '/brand/product-edit/:productId',
      redirectedToMainHost: true,
      load: () =>
        import(/* webpackChunkName: 'brand-product' */ './brand-product'),
    },
    // {
    //   path: '/brand/products/moderation',
    //   redirectedToMainHost: true,
    //   load: () =>
    //     import(
    //       /* webpackChunkName: 'brand-product-moderation' */ './brand-product-moderation'
    //     ),
    // },
    // {
    //   path: '/brand/orders',
    //   redirectedToMainHost: true,
    //   load: () => import(/* webpackChunkName: 'brand-orders' */ './brand-orders'),
    // },

    {
      path: '/profile',
      redirectedToMainHost: true,
      load: () => import(/* webpackChunkName: 'profile' */ './profile'),
    },

    // insert other routes here ...

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }: any) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route?.title ?? ''}`;
    route.description = route?.description ?? '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children!.unshift({
    path: '/error',
    action: require('./error')?.default,
  });
}

export default routes;

import { ApolloClient, gql, from, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';
import { createUploadLink } from 'apollo-upload-client';
import apolloLogger from 'apollo-link-logger';
import { persistCache } from 'apollo-cache-persist';
import createCache from './createCache';
import {
  resolvers as clientResolvers,
  schema as clientSchema,
} from '../../data/graphql/OnMemoryState/schema';

async function initPersistCache(cache: any) {
  try {
    await persistCache({
      cache,
      // @ts-ignore
      storage: window.localStorage,
    });
  } catch (error) {
    console.error('Error restoring Apollo cache', error);
  }
}

export default function createApolloClient() {
  // Restore cache defaults to make the same one in server.js
  const cache = createCache().restore(window.App.cache);

  // Init cache persistence
  (async () => {
    // await before instantiating ApolloClient,
    // else queries might run before the cache is persisted
    await initPersistCache(cache);
  })();

  const restLink = new RestLink({
    uri: window.App.apiUrl,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const link = from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, path }) =>
          console.warn(`[GraphQL error]: Message: %o, Path: %s`, message, path),
        );
      if (networkError) console.warn(`[Network error]: ${networkError}`);
    }),
    ...(__DEV__ ? [apolloLogger] : []),
    restLink,
    createUploadLink({
      uri: window.App.gqlUrl,
      headers: {
        Authorization: window.App.AT !== '' ? `Bearer ${window.App.AT}` : '',
      },
    }),
  ]);

  return new ApolloClient<NormalizedCacheObject>({
    link,
    cache,
    typeDefs: gql(clientSchema),
    resolvers: clientResolvers,
    queryDeduplication: true,
    connectToDevTools: true,
    ssrForceFetchDelay: 100,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
}

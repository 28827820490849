import { InMemoryCache } from '@apollo/client/cache';

export default function createCache() {
  // https://www.apollographql.com/docs/react/caching/cache-configuration/
  return new InMemoryCache({
    typePolicies: {
      CategoryTreeItem: {
        fields: {
          children: {
            // always preferring incoming over existing data
            merge: false,
          },
        },
      },
      Brand: {
        fields: {
          products: {
            // always preferring incoming over existing data
            merge: false,
          },
          hiddenProducts: {
            // always preferring incoming over existing data
            merge: false,
          },
        },
      },
      Product: {
        fields: {
          product: {
            // always preferring incoming over existing data
            merge: false,
          },
        },
      },
    },
  });
}

import UniversalRouter from 'universal-router';
import routes from './routes';

export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    
    // redirect to main domain if page is not related to brand
    if (process.env.BROWSER) {
      const isProd = process.env.NODE_ENV === 'production';
      const domain = isProd ? 'nalune.by' : 'lvh.me:3000';
      const domainWithSubdomain = `.${domain}`;
      const redirectUrl = `http://${domain}${context.pathname}`;
      const host = window?.location.host;

      if (context.route.redirectedToMainHost && host.includes(domainWithSubdomain)) {
        window.location.href = redirectUrl;
      }
    }

    if (typeof context.route.load === 'function') {
      return context.route
        .load()
        .then((action: any) => action?.default(context, params))
        .then((route: any) => ({ ...route, params }));
    }
    if (typeof context.route.action === 'function') {
      return context.route.action(context, params);
    }
    return undefined;
  },
});

import { gql } from '@apollo/client';
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('1234567890', 10);

export const defaults = {
  cart: {
    __typename: 'Cart',
    items: [],
    // total: 0,
  },
};

const GET_CART = gql`
  query cart {
    cart @client {
      items {
        id
        product {
          id
          name
          price
          priceWithDiscount
          currency
          category
          status
          images {
            url
            fallbackUrl
            orderNum
          }
          brand {
            id
            name
            slug
            subdomain
            country
            city
          }
        }
      }
    }
  }
`;

export const resolvers = {
  Mutation: {
    addToCart: (obj: any, { product, params = null }: any, { cache }: any) => {
      const { cart } = cache.readQuery({ query: GET_CART });

      let newProduct: any = {
        __typename: 'CartItem',
        id: nanoid(),
        product,
      };

      if (params) {
        newProduct = {
          ...newProduct,
          params: { __typename: 'CartItemParams', ...params },
        };
      }

      const data = {
        cart: { __typename: 'Cart', items: cart.items.concat(newProduct) },
      };

      cache.writeQuery({
        query: GET_CART,
        data: { ...data },
      });

      return true;
    },

    removeFromCart: (obj: any, { itemId }: any, { cache }: any) => {
      const { cart } = cache.readQuery({ query: GET_CART });

      const updatedCartItems = cart.items.filter(
        (item: any) => item.id !== itemId,
      );

      const data = { cart: { __typename: 'Cart', items: updatedCartItems } };

      cache.writeQuery({
        query: GET_CART,
        data: { ...data },
      });

      return true;
    },

    removeManyFromCart: (obj: any, { itemIds = [] }: any, { cache }: any) => {
      const { cart } = cache.readQuery({ query: GET_CART });

      const updatedCartItems = cart.items.filter(
        (item: any) => !itemIds.includes(item.id),
      );

      const data = { cart: { __typename: 'Cart', items: updatedCartItems } };

      cache.writeQuery({
        query: GET_CART,
        data: { ...data },
      });

      return true;
    },
  },
};

export const schema = [
  `
    type CartProductParams {
      size: String
    }

    type CartItem {
      id: String
      product: Product
      params: CartProductParams
    }

    type Cart {
      items: [CartItem!]
    }
  `,
];

export const queries = [
  `
    cart: Cart!
  `,
];

export const mutations = [
  `
    addToCart(product: Product, params: CartProductParams): Boolean!
    removeFromCart(itemId: String): Boolean!
    removeManyFromCart(itemIds: [String]!): Boolean!
  `,
];

import { gql } from '@apollo/client';

const ORDER_EXPIRATION_TIMEOUT = 60 * 60 * 24 * 14; // 14 days in seconds

export const defaults = {
  orders: {
    __typename: 'Order',
    items: [],
  },
};

const GET_ORDERS = gql`
  query orders {
    orders @client {
      items {
        id
        cartIds
        expiryDate
        items {
          brand {
            id
            name
            slug
            subdomain
            country
            city
            delivery {
              typeName
              price
              currency
            }
            paymentAllowedTypes
          }
          products {
            id
            name
            price
            priceWithDiscount
            currency
            category
            imageDefault {
              url
              fallbackUrl
            }
            images {
              url
              fallbackUrl
              orderNum
            }
            status
          }
        }
      }
    }
  }
`;

export const resolvers = {
  Mutation: {
    createOrder: (
      obj: any,
      { order: { id, items = [], cartIds = [] } }: any,
      { cache }: any,
    ) => {
      const { orders } = cache.readQuery({ query: GET_ORDERS });

      const currentTime = Math.round(new Date().getTime() / 1000); // current timestamp in seconds
      const expiryDate = currentTime + ORDER_EXPIRATION_TIMEOUT;

      const newOrder: any = {
        __typename: 'OrderItem',
        id,
        cartIds,
        items: items.map((item: { brand: any; products: any }) => ({
          __typename: 'BrandOrderItem',
          brand: item.brand,
          products: item.products,
        })),
        expiryDate, // in timestamp format
      };

      // delete expired orders from cache
      const updatedOrderItems = orders.items.filter((item: any) => {
        return item.expiryDate >= currentTime;
      });

      // populate items with new order
      const updatedItems = updatedOrderItems.concat(newOrder);

      cache.writeQuery({
        query: GET_ORDERS,
        data: {
          orders: { __typename: 'Order', items: updatedItems },
        },
      });

      return true;
    },
    removeOrder: (obj: any, { orderId }: any, { cache }: any) => {
      const {
        orders: { items = [] },
      } = cache.readQuery({
        query: GET_ORDERS,
      });

      cache.writeQuery({
        query: GET_ORDERS,
        data: {
          orders: {
            __typename: 'Order',
            items: items.filter(({ id = '' }: any) => id !== orderId),
          },
        },
      });

      return true;
    },
  },
};

export const schema = [
  `
    type BrandOrderItem {
      brand: Brand!
      products: [Product]!
    }

    type OrderItem {
      id: String
      items: [BrandOrderItem]!
      cartIds: [String]!
    }

    type Order {
      items: [OrderItem!]
    }
  `,
];

export const queries = [
  `
    orders: [OrderItem]!
  `,
];

export const mutations = [
  `
    createOrder(order: OrderItem): Boolean!
    removeOrder(orderId: String!): Boolean!
  `,
];

import merge from 'lodash.merge';

import {
  resolvers as cartResolvers,
  schema as cartSchema,
  queries as cartQuery,
  mutations as cartMutation,
} from './cart';

import {
  resolvers as checkoutResolvers,
  schema as checkoutSchema,
  queries as checkoutQuery,
  mutations as checkoutMutation,
} from './checkout';

// Used by both GraphQL Server and Apollo Client
export const schema = [...cartSchema, ...checkoutSchema];

// Below are only used by Apollo Client
export const resolvers = merge(cartResolvers, checkoutResolvers);

// Below are used by GraphQL Server for Introspection
// that generates Flow types by apollo:codegen.
export const queries = [...cartQuery, ...checkoutQuery];
export const mutations = [...cartMutation, ...checkoutMutation];
